import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useActions } from '../../../../hooks/use-actions';
import styles from './ShowBags.module.scss';
import robot2Icon from './robot2.png';
import copypatseIcon from './copypatse.png';
import { BiX } from 'react-icons/bi'
import { toast } from 'react-toastify'
import moment from 'moment';

export default function ShowBags() {
  const bags = useSelector((state) => state.modalSlice.bags);
  const { getBags } = useActions();
  const { deleteBagQuery, deleteBag } = useActions() // api
  const { user } = useSelector((state) => state.modalSlice)

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        await getBags({ telegram_id: 1000498160 });
      } catch (error) {
        console.error(error);
      }
    };
    fetchDataAsync();
    const intervalId = setInterval(fetchDataAsync, 30000);
    return () => clearInterval(intervalId);
  }, [getBags]);

  const shortenAddress = (address) => {
    if (!address || address.length < 10) return address;
    const firstPart = address.slice(0, 4);
    const lastPart = address.slice(-4);
    return `${firstPart}....${lastPart}`;
  };

  const groupAndSortByFilterIdDesc = (dataArray) => {
    const groupedData = dataArray.reduce((acc, item) => {
      const { filter_id } = item;
      if (!acc[filter_id]) acc[filter_id] = [];
      acc[filter_id].push(item);
      return acc;
    }, {});
    Object.keys(groupedData).forEach((filterId) => {
      groupedData[filterId].sort((a, b) => b.id - a.id);
    });
    return Object.keys(groupedData)
      .map((filterId) => ({
        filter_id: filterId,
        items: groupedData[filterId],
      }))
      .sort((a, b) => b.filter_id - a.filter_id);
  };


// console.log('bags', bags)

  const sortedBags = groupAndSortByFilterIdDesc(bags);
function truncateString(str) {
  if (str.length > 25) {
    return str.slice(0, 25) + '...';
  }
  return str;
}


  const handleDelete = (id, name) => {


    let data = {
        id,
        telegram_id: user.telegram_id,
        hash: user.hash
    }
deleteBag(data)
        deleteBagQuery(data)
        toast.success(`Removed ${name}'s Bag`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })
  };

   const [textToCopy, setTextToCopy] = useState('Text to copy');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = (addy, name) => {
    // Create a text area element to hold the text to be copied
    const textArea = document.createElement('textarea');
    textArea.value = addy

    // Append the text area element to the document
    document.body.appendChild(textArea);

    // Select the text within the text area and copy it to the clipboard
    textArea.select();
    document.execCommand('copy');

    // Remove the text area element from the document
    document.body.removeChild(textArea);

    // Set the state to indicate that the text has been copied
    setIsCopied(true);

        toast.success(`Copied ${name}'s address to clipboard`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })
  }

function formatNumber(number) {
  // console.log('number', number)
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 0, // Remove any decimal places
  }).format(number);
}

function formatNumberETH(number, precision = 12) {

  let result = null 

  try {

    // console.log('number', number)
    // Convert the number to a fixed-point notation with the desired precision
    let formattedNumber = parseFloat(number).toFixed(precision);
    
    // Split the formatted number into integer and decimal parts
    let [integerPart, decimalPart] = formattedNumber.split('.');
    
    // Count leading zeros in the decimal part
    let zeroCount = 0;
    while (decimalPart[zeroCount] === '0') {
        zeroCount++;
    }
    
    // Extract the significant part of the decimal after the leading zeros
    let significantPart = decimalPart.slice(zeroCount);

    // Convert zeroCount to subscript using Unicode subscript digits
    const subscriptDigits = '₀₁₂₃₄₅₆₇₈₉';
    let subscriptCount = zeroCount.toString().split('').map(digit => subscriptDigits[digit]).join('');

    // Limit the significant part to 4 digits for readability (you can adjust as needed)
    significantPart = significantPart.slice(0, 4);

    // Format the result as "0.0₇4978" or similar
    result = `0.0${subscriptCount}${significantPart}`;

  } catch(error) {

    console.log('error', error)

  }

  return result;

}

function timeAgo(timestamp) {
  return moment.unix(timestamp).fromNow();
}

/*
  const handleDelete = (id, name) => {
        toast.success(`Removed ${name}'s Bag`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })
  };
*/

function shortenNumber(num) {
    return Number(num.toPrecision(11));
}

// console.log('sortedBags', sortedBags)

function calculatePercentageChange(oldValue, newValue) {
    const change = ((newValue - oldValue) / oldValue) * 100;
    return change.toFixed(2); // Round to 2 decimal places for readability
}

  return (
    <div className={styles.simbuysContainer}>

      {sortedBags.map(({ filter_id, items }) => (
        <div className={styles.buysCard} key={filter_id}>
          <div className={styles.buysHeader}>
            <div className={styles.buysTitle}>
              {items[0].filter_name}
            </div>
          </div>

          {items.map((item) => (
            <div className={styles.buysContainer} key={item.id}>
                <div className={styles.iconCircle}>
      <BiX size={17} color="#fff"  onClick={() => handleDelete(item.id, item.name)} />
    </div>
              <div className={styles.buysRow}>
                <div className={styles.label}>TOKEN</div>
                <div className={`${styles.value} ${styles.highlight}`}>{truncateString(item.name)}</div>
              </div>
              <div className={styles.buysRow}>
                <div className={styles.label}>ADDRESS</div>
                 <div className={styles.valueContainer}>
    <div className={`${styles.value} ${styles.address}`}>
      {shortenAddress(item.address)}
    </div>
    <img 
      src={copypatseIcon} 
      onClick={() => handleCopyToClipboard(item.address, item.name)} 
      className={styles.icon} 
    />
  </div>
              </div>

   


              <div className={styles.buysRow}>
                <div className={styles.label}>INITIAL MARKETCAP</div>
                <div className={`${styles.value} ${styles.positive}`}>${formatNumber(parseFloat(item.initial_marketcap).toFixed(2))}</div>
              </div>

        
              <div className={styles.buysRow}>
                <div className={styles.label}>CURRENT MARKETCAP</div>
                <div className={`${styles.value} ${styles.positive}`}>${formatNumber(parseFloat(item.current_marketcap).toFixed(2))}</div>
              </div>

                            <div className={styles.buysRow}>
                <div className={styles.label}>INITIAL PRICE</div>
                <div className={`${styles.value} ${styles.positive}`}>{formatNumberETH(item.initial_price)}</div>
              </div>

                            <div className={styles.buysRow}>
                <div className={styles.label}>CURRENT PRICE</div>
                <div className={`${styles.value} ${styles.positive}`}>{formatNumberETH(item.current_price)}</div>
              </div>

              <div className={styles.buysRow}>
                <div className={styles.label}>SPENT</div>
                <div className={styles.value}>{item.spent} ETH</div>
              </div>

        
              <div className={styles.buysRow}>
                <div className={styles.label}>BAG</div>
                <div className={`${styles.value} ${styles.bag}`}>{formatNumber(item.amount)} ${item.symbol}</div>
              </div>

                                       <div className={styles.buysRow}>
                <div className={styles.label}>DATE</div>
                <div className={`${styles.value} ${styles.positive}`}>{timeAgo(item.timestamp)}</div>
              </div>

                                                     <div className={styles.buysRow}>
                <div className={styles.label}>LAST UPDATED</div>
                <div className={`${styles.value} ${styles.positive}`}>{timeAgo(item.last_updated)}</div>
              </div>



                                    <div className={styles.buysRow}>
                <div className={styles.label}>CURRENT VALUE</div>
                <div className={`${styles.value} ${styles.bag}`}>{calculatePercentageChange(item.initial_price, item.current_price)}% @ {(item.amount * item.current_price).toFixed(2)} ETH</div>
              </div>


                                    <div className={styles.buysRow}>
                <div className={styles.label}>ACTIVE</div>
                <div className={`${styles.value} ${styles.bag}`}>{item.rugged === true ? 'NO' : 'YES' }%</div>
              </div>


            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

import React from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useActions } from '../../../../hooks/use-actions'
import Button from '../../../ui/Button/Button'
import s from './FilterCreator.module.scss'
import LabelName from './LabelName/LabelName'
import Options from './OptionGM/OptionsGM'

export default function EditFilterGM() {
  const nav = useNavigate()
  const { addSavedQuery, editSavedQuery, resetQuery, editQuery } = useActions()
  const { addQuery, updateQuery } = useActions() // api
  const { savedQueries, query, user } = useSelector((state) => state.modalSlice)
    const noob = useSelector(state => state.modalSlice.noob)
    const tier = useSelector(state => state.modalSlice.tier)
    const cmb_tier = useSelector(state => state.modalSlice.cmb_tier)

  const isEdit = savedQueries.some((sq) => sq.id === query.id)
  console.log('isEdit', isEdit)
  const saveQuery = () => {
    if (!query.selected.length) {
      // return toast.error('Nothing selected')
      return toast.error(`NO OPTIONS WERE SELECTED`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })




    } else if (!query.name) {
      // return toast.error('Enter a name for this filter')
        return toast.error(`ENTER A NAME FOR THIS PROFILE`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })


    } else if (query.selected.includes(3) && (query.selected.includes(4) || query.selected.includes(5) || query.selected.includes(6) || query.selected.includes(7))) {

      return toast.error(`DOESNT MAKE SENSE TO HAVE BOTH BURNED AND LOCKED LIQUIDITY`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })


    }

/*
    if (savedQueries.length > 0) {
      // return toast.error('Nothing selected')
      return toast.error('Free users are limited to one filter. Get some $CHECK for more.', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
    }
*/
    
    if (isEdit) {

      let data = {
        id: query.id,
        selected: query.selected,
        name: query.name,
        telegram_id: user.telegram_id,
        username: user.username,
        hash: user.hash,
                sim: query.sim

      }

      updateQuery(data)
      // editSavedQuery(query)
  


toast.success(`PROFILE '${query.name}' UPDATED. SCANNING MAINNET FOR MATCHES`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })


    } else {

      if (savedQueries.length > 4) {

        
        if (cmb_tier === 0) {

          toast.error(`LIMIT OF 3 SIGNALS FOR FREE USERS`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      })





        } else {


             let data = {
            id: query.id,
            selected: query.selected,
            name: query.name,
            telegram_id: user.telegram_id,
            username: user.username,
            noob: noob,
            hash: user.hash
          }

          addQuery(data)
          // addSavedQuery(query)
      


        toast.success(`Profile '${query.name}' CREATED SCANNING MAINNET FOR MATCHES`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })



        }
   
      } else {

          let data = {
            id: query.id,
            selected: query.selected,
            name: query.name,
            telegram_id: user.telegram_id,
            username: user.username,
            noob: noob,
            hash: user.hash
          }

          addQuery(data)
          // addSavedQuery(query)



        toast.success(`Profile '${query.name}' CREATED, SCANNING MAINNET FOR MATCHES`, {
          style: {
            fontFamily: 'Proto Mono',
            fontSize: '14px',
            textShadow: 'rgba(130, 143, 255) 0px 0px 16px'
          },
          position: toast.POSITION.TOP_RIGHT

        })

      }

    }
    resetQuery()
    nav('/modal/show-filters')
  }

  return (
    <div className={s.container}>
    
   
      <Options />




<div className={s.footer}>
  <input
    type="text"
    value={query.name}
    placeholder="SIGNAL NAME"
    className={s.inputField}
    onChange={(e) => editQuery({ ...query, name: e.target.value })}
  />
  <button className={s.saveButton} onClick={saveQuery}>
    {isEdit ? 'Update' : 'SAVE'}
  </button>
</div>

  
    </div>
  )
}
import React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import backIcon from './back.png'; // Ensure the correct path to the image file
import { useSelector } from 'react-redux'
import { useActions } from '../../../hooks/use-actions';

export default function ModalLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(state => state.modalSlice.user)
    const { savedQueries, query, autobuy, autobuyQuery } = useSelector((state) => state.modalSlice)
  const { resetQuery } = useActions()


        function truncateString(str) {
            if (str.length > 12) {
                return str.slice(0, 12) + '...';
            }
            return str;
        }

    let autoBuyName = null
    let filterName = null 

    if (query !== undefined && query.name !== undefined) {

        filterName = truncateString(query.name)
    }

    if (autobuy !== undefined && autobuy.filterName !== undefined) {



        autoBuyName = truncateString(autobuy.filterName)

    }

    const goBack = () => {

        resetQuery()
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/modal', { replace: true });
        }
    };

    const getPageName = () => {
        const pathParts = location.pathname.split('/').filter(Boolean);
        const currentPage = pathParts[pathParts.length - 1];

        let pageTitle = null

        switch (currentPage) {
            case 'show-filters':
                pageTitle = 'MANAGE PROFILES';
                break;
            case 'edit-filter-gm':
                pageTitle = `EDIT PROFILE (${filterName})`;
                break;
            case 'show-bags':
                pageTitle = 'SIMULATED BUYS';
                break;

            case 'generate-wallet':
                pageTitle = 'MANAGE WALLETS';
                break;

            case 'generate-referral-code':
                pageTitle = 'MANAGE REFERRALS';
                break;

            case 'create-filter':
                pageTitle = `CREATE PROFILE`;
                break;

            case 'auto-buy':
                pageTitle = `CREATE AUTOBUY (${autoBuyName})`;
                break;

            default:
                pageTitle = currentPage.charAt(0).toUpperCase() + currentPage.slice(1).replace('-', ' ');

                break;
        }



        return pageTitle
    };

    return (
        <div style={{ padding: 12 }}>
      <div style={{
        borderBottom: 'solid 1px #fff',
        paddingBottom: 15,
        marginBottom: 15,
        fontSize: 15,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
      }} onClick={goBack}>
        <img src={backIcon} alt="Back" style={{ width: 24, height: 24, position: 'relative', marginRight: '15px' }} />
        {getPageName()}
      </div>
      <Outlet />
    </div>
    );
}